// Library imports
import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';

/**
 * Seo: Seo component
 * Note: Use Helmet to insert custom scripts in header
 * @param param0
 * @returns
 */
export default function Seo({
  description, keywords, title, image, lang, author,
}) {
  const { site } = useStaticQuery(graphql`
    query GetSiteMetadata {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultImage: image
          defaultDescription: description
          author
          siteUrl: url
          defaultKeywords: keywords
        }
      }
    }
  `);

  const { pathname } = useLocation();

  const {
    defaultTitle,
    // titleTemplate, # unused attribute
    defaultDescription,
    siteUrl,
    defaultImage,
    defaultKeywords,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    author,
    locale: lang,
    url: `${siteUrl}${pathname}`,
    keywords: keywords || defaultKeywords,
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={`${seo.title}`}
      htmlAttributes={{
        lang: seo.locale,
      }}
      meta={[
        {
          property: 'og:title',
          content: seo.title,
        },
        {
          property: 'og:description',
          content: seo.description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: seo.author,
        },
        {
          name: 'twitter:title',
          content: seo.title,
        },
        {
          name: 'twitter:description',
          content: seo.description,
        },
      ]}
    >
      <meta name="zd-site-verification" content="xl4kma9wct9gojg78ktq1" />
      <meta name='zd-site-verification' content='vkxy2q4u4flpwijmrud7ir' />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="keywords" content={seo.keywords.join(', ')} />
      <script type="text/javascript">
              {`
window['_fs_host'] = 'fullstory.com';
window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
window['_fs_org'] = '16AT01';
window['_fs_namespace'] = 'FS';
(function(m,n,e,t,l,o,g,y){
    if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
    g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
    o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
    y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
    g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
    g.anonymize=function(){g.identify(!!0)};
    g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
    g.log = function(a,b){g("log",[a,b])};
    g.consent=function(a){g("consent",!arguments.length||a)};
    g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
    g.clearUserCookie=function(){};
    g.setVars=function(n, p){g('setVars',[n,p]);};
    g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
    if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
    g._v="1.3.0";
})(window,document,window['_fs_namespace'],'script','user');
`}
</script>
    </Helmet>
  );
}

Seo.defaultProps = {
  title: 'Incorta Documentation',
  lang: 'en',
  description: null,
  author: null,
  image: null,
  keywords: ['Incorta', 'Documentation', 'United Data Platform', 'Analytics Platform'],
};

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
};
