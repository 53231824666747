const CATEGORIES = {
  blueprints: require('./graph_icon.png').default,
  concepts: require('./brain_icon.png').default,
  // faqs: require('./faqs_icon.svg').default,
  guides: require('./globe_icon.png').default,
  integrations: require('./gear_icon.png').default,
  // newFeatures: require('./new_features_icon.svg').default,
  references: require('./folder_icon.png').default,
  releases: require('./speaker_icon.png').default,
  // releases: require('./medal_icon.png').default,
  tools: require('./toolbox_icon.png').default,
};

export default CATEGORIES;
