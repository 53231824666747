// Library imports
import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

// Component imports
import DocToc from '../components/DocToc';
import NavBar from '../components/NavBar';
import SideBarNav from '../components/SideBarNav';
import Footer from '../components/Footer';
import Homepage from '../components/HomepageComponents/Homepage';

// MDXProvider Component imports
import GifWrapper from '../components/mdxComponents/GifWrapper';
import ImageWrapper from '../components/mdxComponents/ImageWrapper';
import IframeWrapper from '../components/mdxComponents/IframeWrapper';
import ExternalLink from '../components/mdxComponents/ExternalLink';
import TLI from '../components/mdxComponents/TableListItem';
import TL from '../components/mdxComponents/TableList';
import Code from '../components/mdxComponents/Code';
import CardListBuilder from '../components/FeatureComponents/ContentCardList/ContentCardList';

// Utility function Imports
import formatSiteToc from '../utils/tocHelpers';

// Style imports
import '../components/styles.scss';
import '../components/MainContent.scss';
import '../components/prism-theme.scss';
import '../components/Layout.scss';
import MainTemplateStyles from './MainTemplate.module.scss';

// Data imports
import tocHome from '../data/TocContent/tocHome';
import tocCloud from '../data/TocContent/tocCloud';
import tocFiveOne from '../data/TocContent/tocFiveOne';
import tocFiveTwo from '../data/TocContent/tocFiveTwo';
import tocSixZero from '../data/TocContent/tocSixZero';
import tocLatest from '../data/TocContent/tocLatest';



type TocData = {
  name: string;
  link: string;
  children?: TocData[];
};

// Components to pass to MDXProvider for document use.
const components = {
  GifWrapper,
  Homepage,
  ImageWrapper,
  Link,
  IframeWrapper,
  ExternalLink,
  TLI,
  TL,
  CardListBuilder,
  pre: (props) => <Code {...props} />,
};

// Object to structure imported Toc data
const siteTocData = {
  home: tocHome,
  cloud: tocCloud,
  '5.1': tocFiveOne,
  '5.2': tocFiveTwo,
  '6.0': tocSixZero,
  'latest': tocLatest,

};

/**
 * MainTemplate: Main content template.
 * Note: Components for use in MDX are passed at this component with MDXProvider.
 * @param title
 * @param headings
 * @param version
 * @param hidedoctoc
 * @param children
 * @returns
 */
export default function MainTemplate({
  title,
  headings,
  version,
  hidedoctoc,
  children,
}) {
  const siteTocContent: TocData[] = siteTocData[version] || [];

  const mappedSiteToc = formatSiteToc(siteTocContent, useLocation().pathname);

  return (
    <Container
      className={`d-flex flex-column ${MainTemplateStyles.mainContainer}`}
    >
      <Row lg={12} className={MainTemplateStyles.navbarRow}>
        <Col className={MainTemplateStyles.navbarCol}>
          <NavBar pageVersion={version} siteTocArray={mappedSiteToc} />
        </Col>
      </Row>
      <Row className={`${MainTemplateStyles.mainRow} `}>
        <Col
          lg={2}
          className={`d-none d-lg-block ${MainTemplateStyles.sideBarCol}`}
        >
          <div className={MainTemplateStyles.sideBarWrapper}>
            <SideBarNav siteTocArray={mappedSiteToc} />
          </div>
        </Col>
        <Col md={11} lg={8} className="main-content-wrapper">
          <Row>
            <h1 className={MainTemplateStyles.mainHeader}>{title}</h1>
          </Row>
          <Row className={MainTemplateStyles.mainContent}>
            <MDXProvider components={components}>
              <MDXRenderer>{children}</MDXRenderer>
            </MDXProvider>
          </Row>
        </Col>
        {!hidedoctoc && (
          <Col lg={2} className="d-none d-lg-block">
            <DocToc headings={headings} />
          </Col>
        )}
      </Row>
      <Row className={MainTemplateStyles.footerRow}>
        <Col>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
}
