/**
 * activeChild: Identify if the current item has any children that are the active location.
 * @param {*} items
 * @returns
 */
function activeChild(items, currentLocation) {
  if (!Array.isArray(items)) {
    return false;
  }

  for (let i = 0; i < items.length; i += 1) {
    if (items[i].link === currentLocation || items[i].isActive) {
      return true;
    }
  }
  return false;
}

/**
 * formatToc: adds the isActive property to each object in the Array.
 *  The current location and all higher level parents will be set to true.
 *  Depth first traversal
 * @param {*} tocArray
 * @param {*} currentLocation
 * @returns
 */
function formatToc(tocArray, currentLocation) {
  tocArray.forEach((element) => {
    let children;

    if (element.children) {
      children = formatToc(element.children, currentLocation);
    }

    if (
      currentLocation === `${element.link}` ||
      activeChild(children, currentLocation)
    ) {
      element.isActive = true;
    } else {
      element.isActive = false;
    }
  });

  return tocArray;
}

export default formatToc;
