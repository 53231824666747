// Library imports
import { MdExpandLess } from 'react-icons/md';
import React, { useEffect, useState } from 'react';

// Style imports
import ScrollToTopStyles from './ScrollToTop.module.scss';

/**
 * ScrollToTop: Scroll to top funtion with smooth scrolling
 * @returns
 */
export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTopClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    // Show button when page is scorlled upto given distance
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    // cancel subscription to useEffect
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div>
      {isVisible
        && (
        <button
          type="button"
          className={ScrollToTopStyles.scrollToTop}
          onClick={scrollToTopClick}
        >
          <MdExpandLess style={{ fontSize: 36 }} className="text-primary" />
        </button>
        )}
    </div>
  );
}
