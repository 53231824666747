// Library imports
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import CATEGORIES from '../../images/category_icons';

/**
 * ImageWrapper: Identifies requested image and wraps image with the correct tag.
 * @param param0
 * @returns
 */
export default function ImageWrapper({
  src,
  alt = null,
  className = null,
  external = false,
  href = null,
  width = null,
  height = null,
}) {
  if (external) {
    if (href) {
      return (
        <>
          <a href={href} target={external ? '_blank' : ''} rel="noreferrer">
            <img
              src={src}
              alt={alt}
              style={{ width: width || '100%', height: height || 'auto' }}
            />
          </a>
        </>
      );
    }
    return (
      <>
        <img
          style={{ width: width || '100%', height: height || 'auto' }}
          src={src}
          alt={alt}
        />
      </>
    );
  }

  const image = CATEGORIES[src];

  if (!image) {
    return null;
  }
  if (image.includes('svg') || image.includes('png')) {
    if (href) {
      return (
        // SVG images can not be wrapped in a GatsbyImage. Simple <img> tag since SVGs vector scale.
        <>
          <a
            href={href}
            target={external ? '_blank' : ''}
            rel="noopener noreferrer"
          >
            <img
              className={className}
              src={image}
              alt={alt}
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
        </>
      );
    }
    // SVG images can not be wrapped in a GatsbyImage. Simple <img> tag since SVGs vector scale.
    return (
      <>
        <img className={className} src={image} alt={alt} />
      </>
    );
  }

  const imageData = getImage(image);

  if (href) {
    return (
      <>
        <a
          href={href}
          target={external ? '_blank' : ''}
          rel="noopener noreferrer"
        >
          <GatsbyImage
            className={className}
            image={imageData}
            alt={alt}
            style={{ width: '100%', height: 'auto' }}
          />
        </a>
      </>
    );
  }
  return <GatsbyImage className={className} image={imageData} alt={alt} />;
}
