// Library imports
import React from 'react';
import Image from 'react-bootstrap/Image';

/**
 * GifWrapper: Wrapper to properly size gifs.
 * Note: Created component to abstract work for writers and provide easier global customization.
 * @param src
 * @returns
 */
export default function GifWrapper({
  src,
  href = null,
  external = false,
  alt = null,
  height = null,
  width = null,
}) {
  if (href) {
    return (
      <>
        <a
          href={href}
          target={external ? '_blank' : ''}
          rel="noopener noreferrer"
        >
          <Image
            fluid
            src={src}
            style={{ width: width || '100%', height: height || 'auto' }}
            alt={alt}
          />
        </a>
      </>
    );
  } if (src.includes('://')) {
    return (
      <Image fluid src={src} alt={alt} style={{ width: width || '100%', height: height || 'auto' }} />
    );
  }
  return (
    <Image src={src} alt={alt} width="100%" height="auto" style={{ width: width || '100%', height: height || 'auto' }} />
  );
}
