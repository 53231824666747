// Library imports
import React from 'react';
import { Card, Button } from 'react-bootstrap';

// Style imports
import cardStyles from './homeMainCard.module.scss';

/**
 * HomeMainCard: Creates the main Cloud and On-Premises cards on the Homepage of the Docs site.
 * @param cardData
 * @returns
 */
export default function HomeMainCard({ cardData }) {
  return (
    <Card bsPrefix={cardStyles.card}>
      <Card.Img className={cardStyles.cardImg} src={cardData.image} />
      <Card.Body className={cardStyles.cardBody}>
        <Card.Title className={cardStyles.title}>{cardData.title}</Card.Title>
        <hr className={cardStyles.hr} />
        <Card.Text className={cardStyles.text}>{cardData.text}</Card.Text>
        <div className={cardStyles.buttonWrapper}>
          <Button
            variant={cardData.buttonStyle}
            className={`${cardData.buttonStyle} ${cardStyles.mainButton}`}
            href={cardData.link}
          >
            {cardData.buttonText}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
