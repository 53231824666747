// Library imports
import React from 'react';
import { graphql } from 'gatsby';

// Component imports
import Seo from './Seo';
import MainTemplate from '../templates/MainTemplate';
import IframeTemplate from '../templates/IframeTemplate';
import ScrollToTop from '../components/ScrollToTop';

// Style imports
import '../components/styles.scss';

/**
 * RootLayout: The primary page Layout. Non visible content only. Exception: ScrollToTop.
 * Note: Only component that can query with parameters. Gatsby limits to top component.
 * @param data
 * @returns
 */
function RootLayout({ data }) {
  const { doc } = data;
  const { title, version, iframe, hidedoctoc, tags, excerpt } = doc.frontmatter;
  const { headings, body } = doc;

  return (
    <>
      <Seo title={title} keywords={tags} description={excerpt} />
      {iframe && <IframeTemplate>{body}</IframeTemplate>}
      {!iframe && (
        <MainTemplate
          headings={headings}
          title={title}
          version={version}
          hidedoctoc={hidedoctoc}
        >
          {body}
        </MainTemplate>
      )}
      <ScrollToTop />
    </>
  );
}

export default RootLayout;

export const query = graphql`
  query ($id: String) {
    doc: mdx(id: { eq: $id }) {
      frontmatter {
        slug
        version
        title
        tags
        iframe
        hidedoctoc
      }
      headings {
        depth
        value
      }
      excerpt(pruneLength: 300)
      body
    }
  }
`;
