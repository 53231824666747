// Library imports
import React from 'react';
import { Link } from 'gatsby';

// Content imports
import { CARDCONTENT } from '../../../data/CardContent';
import styles from './ContentCardList.module.scss';

// Type for the card content data
type CardContent = {
  name: string;
  type: 'INCORTA' | 'CDATA';
  link: string;
  image?: string;
};

// Type for Card Content Map
type CardContentMap = {
  version: 'latest' | 'cloud' | '6.0' | '5.2' | '5.1';
  category: string;
  type: string;
  subtype: string | null;
};

/**
 * Card
 * Individual Card generator
 * @param props
 * props contain: card; the card object data.
 * @returns An individual card element populated with prop data
 */
function Card(props: { card: CardContent }) {
  const name = props.card.name;
  const type = props.card.type;
  const link = props.card.link;
  const image = props.card.image;
  return (
    <Link to={link}>
      <div className={styles.card}>
        {image && <img src={image} />}
        {!image && <img src={'http://example.com/non-existent-image.jpg'} />}
        <h4>{name}</h4>
        {type === 'CDATA' && <span className={styles.cdataRibbon}></span>}
      </div>
    </Link>
  );
}

/**
 * ContentCardList
 * Takes in the array of collected card data
 * @param props
 * @returns An empty element if no content exists or list of cards with data mapped from array to each Card.
 */
function ContentCardList(props: { cardList: CardContent[] }) {
  const cardList = props.cardList;
  if (cardList == null) {
    return <></>;
  }
  return (
    <div className={styles.cardList}>
      {cardList.map((card: CardContent) => {
        return <Card card={card} key={card.name} />;
      })}
    </div>
  );
}

/**
 * cardListSearch
 * Helper function that takes in defined parameters and identifies required data from CARDCONTENT source.
 * @param param0
 * @returns The desired content data
 */
function cardListSearch({ version, category, type, subtype }: CardContentMap) {
  let cardListData: CardContent[];

  for (const element of CARDCONTENT) {
    if (
      element.version === version &&
      element.category === category &&
      element.type === type &&
      (subtype ? element.subtype === subtype : element.subtype == subtype)
    ) {
      cardListData = element.source;
      break;
    }
  }

  return cardListData;
}

/**
 * CardListBuilder
 * Entry point function. Pass parameters to cardListSearch and send data to the card list generator.
 * @param param0
 * @returns ContentCardList with passed parameters returns created card elements
 */
function CardListBuilder({
  version,
  category,
  type,
  subtype = null,
}: CardContentMap) {
  return (
    <ContentCardList
      cardList={cardListSearch({ version, category, type, subtype })}
    />
  );
}

export default CardListBuilder;
