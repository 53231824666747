import React, { Children } from 'react';

/**
 * TableList: To be used inside of a table using an embeded list.
 * Primary use of function is to identify and lists inside of a table that used
 * HTML valid syntax that is not JSX valid.
 * This function can be used in combination with the TableListItem function
 * to identify list items from MD to MDX migration.
 *
 * Function will identify child strings and wrap them in <li> tags.
 * If another list is found as a child (<TableList /> explicitly), function will
 * recursively drill down.
 *
 *
 * @param param0
 * @returns
 */

// Property 'props' does not exist on type 'number | {} | ReactElement<any, string |
// JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal'.
//   Property 'props' does not exist on type 'number'.

function TableList(props) {
  const children = props.children;
  const validMdxTypes = ['strong', 'em', 'bold', 'inlineCode'];
  const arrayChildren = Children.toArray(children);

  return (
    <ul
      style={{
        marginLeft: '0rem',
      }}
    >
      {Children.map(arrayChildren, (child) => {
        if (child) {
          // typeof child === 'string' ||
          //   validMdxTypes.includes(child.props.mdxType);
          if (typeof child === 'string' && !child.replace(/\s/g, '').length) {
            return null;
          }

          if (child.props?.mdxType === 'TLI') {
            return null;
          }

          return <li className="tableListChild">{child}</li>;
        }
        // To resolve: Why the editor does not see the props property. TS interface.
        if (child.props?.mdxType === 'TL') {
          return <TableList>{child.props.children}</TableList>;
        }
        return null;
      })}
    </ul>
  );
}

export default TableList;
