// Library imports
import React, { useState } from 'react';
import { Container, Row, Col, Tabs, Tab, Stack } from 'react-bootstrap';

// Component imports
import TabCard from './TabCard';
import HomeMainCard from './HomeMainCard';

// Data imports
import { lastRelease, cloudRelease } from '../../config/site';

// Image asset imports
import LatestReleaseImage from '../../images/homepage_images/latest_release_icon.png';
import PastReleasesImage from '../../images/homepage_images/past_releases_icon.png';

// Style imports
import styles from './Homepage.module.scss';

// Data for the Cloud and OnPrem cards
const mainCards = [
  {
    title: 'Latest Release Documentation',
    link: cloudRelease,
    text: 'Learn how to acquire, enrich, analyze, and act on data using the latest Incorta Cloud and On-Premises releases starting 2024.1.0.',
    buttonText: 'Go to Latest Release Docs',
    buttonStyle: 'primary',
    image: LatestReleaseImage,
  },
  {
    title: 'All Published Documentation',
    link: lastRelease,
    text: 'Access all published versions of Incorta documentation .',
    buttonText: 'Check All Docs',
    buttonStyle: 'primary',
    image: PastReleasesImage,
  },
];

/*
// Data for the quick nav Cloud cards
const cloudQuickNavCards = [
  {
    title: 'Start',
    link: '/cloud/guides-start',
    text: 'Learn how to ingest and analyze your data in Incorta.',
    buttonText: 'Start using Incorta',
    headerColor: 'colorGuides',
    buttonStyle: 'secondary',
  },
  {
    title: 'Excel add-in',
    link: '/cloud/integrations-microsoft-excel-add-in',
    text: 'Learn how to extract your data from Incorta into Excel for quick analysis.',
    buttonText: 'Discover the excel add-in',
    headerColor: 'colorIntegrations',
    buttonStyle: 'secondary',
  },
  {
    title: 'Built-in functions',
    link: '/cloud/references-built-in-functions',
    text: 'Learn about the Incorta built-in functions and how to use them.',
    buttonText: 'Explore built-in functions',
    headerColor: 'colorReferences',
    buttonStyle: 'secondary',
  },
];

// Data for the quick nav OnPrem cards
const onpremQuickNavCards = [
  {
    title: 'Start',
    link: '/6.0/guides-start',
    text: 'Learn how to ingest and analyze your data in Incorta.',
    buttonText: 'Start using Incorta',
    headerColor: 'colorGuides',
    buttonStyle: 'secondary',
  },
  {
    title: 'Data applications',
    link: '/6.0/blueprints-all',
    text: 'Learn how to quickly connect to your business applications and leverage pre-built dashboards to answer pressing business questions.',
    buttonText: 'Explore data applications',
    headerColor: 'colorBlueprints',
    buttonStyle: 'secondary',
  },
  {
    title: 'Built-in functions',
    link: '/6.0/references-built-in-functions',
    text: 'Learn about the Incorta built-in functions and how to use them.',
    buttonText: 'Explore built-in functions',
    headerColor: 'colorReferences',
    buttonStyle: 'secondary',
  },
];
*/

/**
 *  Homepage compoent defines the layout of the Docs Homepage.
 * @param props
 * @returns
 */


export default function Homepage() {
  const [selected, setSelected] = useState('Cloud');

  return (
    <Container>
      <Col>
        <Stack gap={3}>
          <Row>
            <p>
              Find the guides, references, and other resources you need to
              jumpstart your data management and analytics experience with
              Incorta.
            </p>
          </Row>


          <Row
            xs={1}
            sm={1}
            md={2}
            lg={2}
            className={`${styles.mainCardRow} g-2`}
          >
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <HomeMainCard cardData={mainCards[0]} />
            </Col>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <HomeMainCard cardData={mainCards[1]} />
            </Col>
          </Row>
        </Stack>
      </Col>
    </Container>
  );
}
