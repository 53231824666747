const tocHome = [
  {
    name: 'Home',
    link: '',
  },
  {
    name: 'Latest Release Docs',
    link: '/latest',
  },
  {
    name: 'Previous Releases',
    link: '/home/all-docs',
    children: [
      {
        name: 'Cloud Docs',
        link: '/cloud',
      },
      {
        name: '6.0 Docs',
        link: '/6.0',
      },
      {
        name: '5.2 Docs',
        link: '/5.2',
      },
      {
        name: '5.1 Docs',
        link: '/5.1',
      },
      {
        name: 'Legacy Releases',
        link: 'https://docs4.incorta.com',
      },
    ],
  },
  {
    name: 'Release support policy',
    link: '/home/incorta-release-support-policy',
  },
  {
    name: 'Release Model',
    link: '/home/release-model',
  },
];

module.exports = tocHome;
