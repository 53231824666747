// Library imports
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { ListGroup } from 'react-bootstrap';
import ScrollSpy from 'react-scrollspy';

// Style imports
import './DocToc.scss';

// Convert the header name to slug format
function toSlug(heading) {
  return `${heading.value
    .replace(/[|&;$%@"*/:<>().+,]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()}`;
}

/**
 * DocToc: Recieve the document headings, with slug and depth attributes, and structure into list
 * @param headings
 * @returns
 */
export default function DocToc({ headings }) {
  const [headingList] = useState(() => {
    if (headings[0] !== undefined) {
      return headings.map((heading) => toSlug(heading));
    }
    return null;
  });

  if (!headings || headings.length === 0) {
    return null;
  }

  return (
    <ListGroup className="doc-toc-list-group">
      <ul className="doc-toc">
        <h5 className="toc-title">Content</h5>
        <div className="inner-scroll">
          <ScrollSpy
            items={headingList}
            currentClassName="active"
            componentTag="toc-link"
          >
            {headings.map((heading) => {
              const headingSlug = toSlug(heading);
              return (
                <li
                  className="toc-element"
                  key={heading.value}
                  style={{ marginLeft: `${-2 + heading.depth}em` }}
                >
                  <Link
                    className="toc-link"
                    id={`${headingSlug}`}
                    to={`#${headingSlug}`}
                  >
                    {heading.value}
                  </Link>
                </li>
              );
            })}
          </ScrollSpy>
        </div>
      </ul>
    </ListGroup>
  );
}
