import React from 'react';

/**
 * TableListItem: Replace <li> tags without closing tags with a line break
 *   and bullet point if not in a <ul> parent.
 * This function is to be used in combination with the TableList function
 * to identify list items from md to mdx migration.
 * @returns A <div> tag.
 */
function TableListItem() {
  return (
    <>
      <br />
      &nbsp;&nbsp;&#x25cf;&nbsp;&nbsp;
    </>
  );
}

export default TableListItem;
