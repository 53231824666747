// Library imports
import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { Link } from 'gatsby';

// MDXProvider Component imports
import GifWrapper from '../components/mdxComponents/GifWrapper';
import ImageWrapper from '../components/mdxComponents/ImageWrapper';
import IframeWrapper from '../components/mdxComponents/IframeWrapper';
import ExternalLink from '../components/mdxComponents/ExternalLink';
import TLI from '../components/mdxComponents/TableListItem';
import TL from '../components/mdxComponents/TableList';

// Style imports
import './IframeMainContent.scss';
import '../components/prism-theme.scss';
import '../components/styles.scss';

// Components to pass to MDXProvider for document use.
const components = {
  GifWrapper,
  ImageWrapper,
  Link,
  IframeWrapper,
  ExternalLink,
  TLI,
  TL,
};

/**
 * IframeTemplate: Template Wrapper for iframe content only.
 * Note: The expected styling for this content is structured for the data wizard window.
 * @param children
 * @returns
 */
export default function IframeTemplate({ children }) {
  return (
    <div className="iframe-main-content-wrapper">
      <MDXProvider components={components}>
        <MDXRenderer>{children}</MDXRenderer>
      </MDXProvider>
    </div>
  );
}
