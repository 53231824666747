// Library imports
import React from 'react';

/**
 * GifWrapper: Wrapper to properly size and display iframe content.
 * Note: Created component to abstract work for writers and provide easier global customization.
 * @param src
 * @returns
 */
export default function IframeWrapper({
  src, width = null, height = '585px', href = null, title = 'iframeContent',
}) {
  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        style={{
          position: 'relative',
          display: 'inline-block',
        }}
        rel="noreferrer"
      >
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '90%',
            zIndex: 1,
          }}
        />
        <iframe
          title={title}
          aria-hidden={title === 'iframeContent'}
          src={src}
          frameBorder={0}
          allowFullScreen={false}
          width={width || '100%'}
          height={height}
        />
      </a>
    );
  }
  return (
    <iframe
      title={title}
      aria-hidden={title === 'iframeContent'}
      src={src}
      frameBorder={0}
      allowFullScreen
      width={width || '100%'}
      height={height}
    />
  );
}
