type CardContentSourceMap = {
  version: string;
  category: string;
  type: string;
  subtype?: string;
  source: any;
};

export const CARDCONTENT: CardContentSourceMap[] = [
  {
    version: 'cloud',
    category: 'references',
    type: 'connectors',
    subtype: 'dataFile',
    source: require('./cloud/references/connectors/connectors').dataFile,
  },
  {
    version: 'cloud',
    category: 'references',
    type: 'connectors',
    subtype: 'application',
    source: require('./cloud/references/connectors/connectors').application,
  },
  {
    version: 'cloud',
    category: 'references',
    type: 'connectors',
    subtype: 'database',
    source: require('./cloud/references/connectors/connectors').database,
  },
  {
    version: 'cloud',
    category: 'references',
    type: 'connectors',
    subtype: 'fileSystem',
    source: require('./cloud/references/connectors/connectors').fileSystem,
  },
  {
    version: 'cloud',
    category: 'references',
    type: 'connectors',
    subtype: 'queryService',
    source: require('./cloud/references/connectors/connectors').queryService,
  },
  {
    version: 'cloud',
    category: 'references',
    type: 'connectors',
    subtype: 'dataLake',
    source: require('./cloud/references/connectors/connectors').dataLake,
  },
  {
    version: 'cloud',
    category: 'references',
    type: 'connectors',
    subtype: 'custom',
    source: require('./cloud/references/connectors/connectors').custom,
  },
  {
    version: 'cloud',
    category: 'references',
    type: 'connectors',
    subtype: 'streamingData',
    source: require('./cloud/references/connectors/connectors').streamingData,
  },
  {
    version: 'cloud',
    category: 'references',
    type: 'connectors',
    subtype: 'other',
    source: require('./cloud/references/connectors/connectors').other,
  },

  {
    version: '5.1',
    category: 'references',
    type: 'connectors',
    subtype: 'dataFile',
    source: require('./5.1/references/connectors/connectors').dataFile,
  },
  {
    version: '5.1',
    category: 'references',
    type: 'connectors',
    subtype: 'application',
    source: require('./5.1/references/connectors/connectors').application,
  },
  {
    version: '5.1',
    category: 'references',
    type: 'connectors',
    subtype: 'database',
    source: require('./5.1/references/connectors/connectors').database,
  },
  {
    version: '5.1',
    category: 'references',
    type: 'connectors',
    subtype: 'fileSystem',
    source: require('./5.1/references/connectors/connectors').fileSystem,
  },
  {
    version: '5.1',
    category: 'references',
    type: 'connectors',
    subtype: 'queryService',
    source: require('./5.1/references/connectors/connectors').queryService,
  },
  {
    version: '5.1',
    category: 'references',
    type: 'connectors',
    subtype: 'dataLake',
    source: require('./5.1/references/connectors/connectors').dataLake,
  },
  {
    version: '5.1',
    category: 'references',
    type: 'connectors',
    subtype: 'custom',
    source: require('./5.1/references/connectors/connectors').custom,
  },
  {
    version: '5.1',
    category: 'references',
    type: 'connectors',
    subtype: 'streamingData',
    source: require('./5.1/references/connectors/connectors').streamingData,
  },
  {
    version: '5.1',
    category: 'references',
    type: 'connectors',
    subtype: 'other',
    source: require('./5.1/references/connectors/connectors').other,
  },

  {
    version: '5.2',
    category: 'references',
    type: 'connectors',
    subtype: 'dataFile',
    source: require('./5.2/references/connectors/connectors').dataFile,
  },
  {
    version: '5.2',
    category: 'references',
    type: 'connectors',
    subtype: 'application',
    source: require('./5.2/references/connectors/connectors').application,
  },
  {
    version: '5.2',
    category: 'references',
    type: 'connectors',
    subtype: 'database',
    source: require('./5.2/references/connectors/connectors').database,
  },
  {
    version: '5.2',
    category: 'references',
    type: 'connectors',
    subtype: 'fileSystem',
    source: require('./5.2/references/connectors/connectors').fileSystem,
  },
  {
    version: '5.2',
    category: 'references',
    type: 'connectors',
    subtype: 'queryService',
    source: require('./5.2/references/connectors/connectors').queryService,
  },
  {
    version: '5.2',
    category: 'references',
    type: 'connectors',
    subtype: 'dataLake',
    source: require('./5.2/references/connectors/connectors').dataLake,
  },
  {
    version: '5.2',
    category: 'references',
    type: 'connectors',
    subtype: 'custom',
    source: require('./5.2/references/connectors/connectors').custom,
  },
  {
    version: '5.2',
    category: 'references',
    type: 'connectors',
    subtype: 'streamingData',
    source: require('./5.2/references/connectors/connectors').streamingData,
  },
  {
    version: '5.2',
    category: 'references',
    type: 'connectors',
    subtype: 'other',
    source: require('./5.2/references/connectors/connectors').other,
  },

  {
    version: '6.0',
    category: 'references',
    type: 'connectors',
    subtype: 'dataFile',
    source: require('./6.0/references/connectors/connectors').dataFile,
  },
  {
    version: '6.0',
    category: 'references',
    type: 'connectors',
    subtype: 'application',
    source: require('./6.0/references/connectors/connectors').application,
  },
  {
    version: '6.0',
    category: 'references',
    type: 'connectors',
    subtype: 'database',
    source: require('./6.0/references/connectors/connectors').database,
  },
  {
    version: '6.0',
    category: 'references',
    type: 'connectors',
    subtype: 'fileSystem',
    source: require('./6.0/references/connectors/connectors').fileSystem,
  },
  {
    version: '6.0',
    category: 'references',
    type: 'connectors',
    subtype: 'queryService',
    source: require('./6.0/references/connectors/connectors').queryService,
  },
  {
    version: '6.0',
    category: 'references',
    type: 'connectors',
    subtype: 'dataLake',
    source: require('./6.0/references/connectors/connectors').dataLake,
  },
  {
    version: '6.0',
    category: 'references',
    type: 'connectors',
    subtype: 'custom',
    source: require('./6.0/references/connectors/connectors').custom,
  },
  {
    version: '6.0',
    category: 'references',
    type: 'connectors',
    subtype: 'streamingData',
    source: require('./6.0/references/connectors/connectors').streamingData,
  },
  {
    version: '6.0',
    category: 'references',
    type: 'connectors',
    subtype: 'other',
    source: require('./6.0/references/connectors/connectors').other,
  },

  {
    version: 'latest',
    category: 'references',
    type: 'connectors',
    subtype: 'dataFile',
    source: require('./latest/references/connectors/connectors').dataFile,
  },
  {
    version: 'latest',
    category: 'references',
    type: 'connectors',
    subtype: 'application',
    source: require('./latest/references/connectors/connectors').application,
  },
  {
    version: 'latest',
    category: 'references',
    type: 'connectors',
    subtype: 'database',
    source: require('./latest/references/connectors/connectors').database,
  },
  {
    version: 'latest',
    category: 'references',
    type: 'connectors',
    subtype: 'fileSystem',
    source: require('./latest/references/connectors/connectors').fileSystem,
  },
  {
    version: 'latest',
    category: 'references',
    type: 'connectors',
    subtype: 'queryService',
    source: require('./latest/references/connectors/connectors').queryService,
  },
  {
    version: 'latest',
    category: 'references',
    type: 'connectors',
    subtype: 'dataLake',
    source: require('./latest/references/connectors/connectors').dataLake,
  },
  {
    version: 'latest',
    category: 'references',
    type: 'connectors',
    subtype: 'custom',
    source: require('./latest/references/connectors/connectors').custom,
  },
  {
    version: 'latest',
    category: 'references',
    type: 'connectors',
    subtype: 'streamingData',
    source: require('./latest/references/connectors/connectors').streamingData,
  },
  {
    version: 'latest',
    category: 'references',
    type: 'connectors',
    subtype: 'other',
    source: require('./latest/references/connectors/connectors').other,
  },
];
