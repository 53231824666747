// Library imports
import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/duotoneLight';

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

// Copy button styling. Styling here since this only effects this one element.
const Button = (props) => (
  <button
    type="button"
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
      border: 'none',
      boxShadow: 'none',
      textDecoration: 'none',
      margin: '7px',
      padding: '7px 11px',
      background: '#797e4f1f',
      borderRadius: '7px',
      cursor: 'pointer',
      color: '#7e8c9e',
      fontSize: '14px',
      fontFamily: 'sans-serif',
      lineHeight: '1',
    }}
    {...props}
  />
);

/**
 * Code: Styling for multiline code blocks.
 * @param param0
 * @returns
 */
function Code({ children }) {
  const [isCopied, setIsCopied] = React.useState(false);

  // Pull the className
  const className = children.props?.className;
  const code = children.props.children;
  const language = className ? className.replace(/language-/, '') : '';

  return (
    <Highlight
      {...defaultProps}
      code={code}
      language={language}
      theme={theme}
    >
      {({
        style, tokens, getLineProps, getTokenProps,
      }) => (
        // Styling here since this only effects this one element.
        <pre
          className={className}
          style={{
            ...style,
            padding: '1rem 4rem 0rem 1rem',
            position: 'relative',
            whiteSpace: 'pre-wrap',
            border: '1px solid #7f835d1f',
            borderRadius: '5px',
          }}
        >
          <Button onClick={() => {
            copyToClipboard(code);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
          }}
          >
            {isCopied ? 'Copied' : 'Copy'}
          </Button>
          {tokens.map((line, index) => {
            const lineProps = getLineProps({ line, key: index });
            return (
              <div key={index} {...lineProps}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            );
          })}
        </pre>
      )}
    </Highlight>
  );
}

export default Code;
