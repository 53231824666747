const LOGOS = {
  alloyDB: require('./alloydb.svg').default,
  apacheDrill: require('./apache-drill.png').default,
  apacheHive: require('./apache-hive.png').default,
  apacheKafka: require('./apache-kafka.png').default,
  athena: require('./athena.png').default,
  autoline: require('./autoline.svg').default,
  aws: require('./aws.png').default,
  box: require('./box.png').default,
  cassandra: require('./cassandra.png').default,
  cdata: require('./cdata.png').default,
  ciscoMeraki: require('./meraki.png').default,
  cosmosDB: require('./cosmos-db.png').default,
  customSql: require('./custom-sql.png').default,
  dropbox: require('./dropbox.png').default,
  dynamoDB: require('./dynamodb.png').default,
  file: require('./file.png').default,
  freshservice: require('./freshservice.png').default,
  ftp: require('./ftp.png').default,
  googleCloudStorage: require('./google-cloud-storage.png').default,
  googleDrive: require('./google-drive.png').default,
  googleBigQuery: require('./google-big-query.png').default,
  googleSheets: require('./google-sheets.png').default,
  graphQL: require('./graphql.svg').default,
  hdfs: require('./hdfs.png').default,
  ibm: require('./ibm.png').default,
  jira: require('./jira.png').default,
  json: require('./JSON.png').default,
  kyuubi: require('./kyuubi.svg').default,
  mariadb: require('./mariadb.svg').default,
  microsoftAzureGen2: require('./microsoft-azure-gen2.png').default,
  microsoftSharepoint: require('./microsoft-sharepoint.png').default,
  microsoftSQLServer: require('./microsoft-sql-server.png').default,
  msonedrive: require('./ms-onedrive.svg').default,
  mongoDB: require('./mongo-db.png').default,
  mySQL: require('./my-sql.png').default,
  netsuite: require('./netsuite.png').default,
  onelogin: require('./onelogin.png').default,
  oracle: require('./oracle.png').default,
  oracleB2c: require('./oracle-b2c.png').default,
  oracleCloudApplications: require('./oracle-cloud-applications.png').default,
  oracleEssbase: require('./oracle-essbase.png').default,
  oracleNetsuiteSuiteAnalytics: require('./oracle-netsuite-suite-analytics.png')
    .default,
  oracleNetsuiteWebservice: require('./oracle-netsuite-webservice.png').default,
  oracleSalesCloud: require('./oracle-sales-cloud.png').default,
  oracleCPQ: require('./oraclecpq.svg').default,
  oracleOTM: require('./oracleotmgtm.svg').default,
  postgreSQL: require('./postgresql.png').default,
  presto: require('./presto.png').default,
  redshift: require('./redshift.png').default,
  salesforce: require('./salesforce.png').default,
  sap: require('./sap.png').default,
  serviceNow: require('./servicenow.png').default,
  sftp: require('./sftp.png').default,
  smartsheet: require('./smartsheet.png').default,
  snowflake: require('./snowflake.png').default,
  splunk: require('./splunk.png').default,
  teradata: require('./teradata.png').default,
  upload: require('./upload.png').default,
  vertica: require('./vertica.png').default,
  zuora: require('./zuora.png').default,
};

export default LOGOS;
